import React from "react";
import Layout from "components/layout";
import Seo from "components/seo";

function Privacy() {
  return (
    <Layout>
      <Seo title="Privacy" />
      <section className="w-full bg-[#1D1C1C] p-5 pb-24 font-primary">
        <div className="border-2 border-[#2664e4] text-white">
          <p className="w-ful bg-[#2664E4] text-17 font-medium text-center uppercase mt-5 mb-3 py-1">Privacy</p>
          <div className="p-5">
            <h3 className="text-[22px] font-medium uppercase my-10">
              PLEASE READ THE FOLLOWING CAREFULLY. THE AFOREMENTIONED IS THE PRIVACY POLICY THAT GOVERNS YOUR USE OF ANY TOMEDES
              SERVICE
            </h3>
            <div className="mb-16 text-[20px] space-y-4">
              <p className="font-light">
                Our email privacy policy demonstrates our firm commitment to your privacy and the protection of your
                information.
              </p>
              <p className="font-light">This Privacy Policy ("Privacy Policy") applies to Tomedes.com</p>
              <p className="font-light">As revised on: November 1, 2020</p>
              <p className="font-light">
                At Tomedes, we recognize the privacy needs of our Customers. You should be able to choose what information you
                want to share and decide what will happen to that information once it has been shared. Protecting your personal
                information is vitally important. Please read the following Privacy Policy to understand how your personal
                information will be treated as you make full use of our many offerings. This policy may change from time to time
                without notice so please check back periodically. We hope this will increase your awareness about online
                privacy.
              </p>
            </div>

            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Collected Information</h4>
              <p className="font-light text-[20px] mb-10">
                If you choose to use and or purchase a Tomedes product or place an order with us, we may ask you to provide us
                with certain personal information, such as your name, physical and electronic mail ("e-mail") addresses, phone
                number, domain name and other information that by itself, or in combination with other information, may be
                identifiable to you as a specific individual. We may also ask for financial information, such as account or
                credit card numbers, and demographic information, such as your zip code.
              </p>

              <p className="font-light text-[20px]">
                Please be aware that Tomedes advertisers, and web sites that have links on our sites, and provider/distributor
                partners may also collect personally identifiable information about you. The information practices of these
                Internet access partners, advertisers, and web sites linked to Tomedes and provider/distributor partners, are
                not governed by this Privacy Policy. What these other sites do with that information is governed by the privacy
                policy for that site and not this policy. You are encouraged to review the privacy policy on any site you visit.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Information Requests</h4>
              <p className="font-light text-[20px]">
                You may be able to request information on our sites regarding products and Services, including technical support
                and customer Service, by using online forms. We may ask that you provide certain contact information to allow us
                to fulfill your request and to send you information about Tomedes products and Services.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Security</h4>
              <p className="font-light text-[20px]">
                Ordering online for a Tomedes product or service is secure and convenient. Our ordering process is protected by
                the Secure Sockets Layer ("SSL") protocol, which encrypts your information and confirms the identity of the
                Tomedes server before completing your transaction. Netscape Navigator 2.0 + (or better) and Internet Explorer
                3.0+ (or better) support the SSL protocol. We recommend you use the latest browsers to ensure that you are
                protected by advances in security technology.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Children's Guidelines</h4>
              <p className="font-light text-[20px]">
                Although some of Tomedes sites are not geared towards children, Tomedes nonetheless attempts to protect
                children. Tomedes must, however, rely on the information provided to us by visitors to our sites. As a result,
                it is impossible for us to guarantee with absolute certainty that the information collected and utilized by
                Tomedes is not derived from a child.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Disclosure</h4>
              <p className="font-light text-[20px]">Tomedes</p>
              <p className="font-normal text-[20px]">
                will not sell, rent, trade, or disclose individual Customer information to any third parties or outside
                companies
              </p>
              <p className="font-light text-[20px]">
                who will use the information to contact you or who will share the information with others without your
                permission. It may be necessary to disclose information in special cases when we have reason to believe that
                disclosing the information is necessary to identify, contact or bring legal action against someone who may be
                violating Tomedes policies or may be causing injury to or interference with (either intentionally or
                unintentionally) the rights or property of Tomedes, other Tomedes users, or anyone else that could be harmed by
                such activities. Tomedes may disclose or access account information when we believe in good faith that the law
                requires it or for administrative and other purposes that we deem necessary to maintain, service, and improve
                our products and Services.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Use of Information</h4>
              <p className="font-light text-[20px] mb-10">
                The use of an individual's e-mail address is necessary to keep our Customers well informed of maintenance
                issues, escalated critical issues and problem resolutions that may arise with your Tomedes Service. We may also
                use e-mail addresses to provide our Customers with informative newsletters.
              </p>
              <p className="font-light text-[20px] mb-10">
                We like to keep our Customers informed via e-mail and other means. Sometimes Tomedes uses personal and
                demographic information to help us determine which Tomedes products and Services are most likely to be of
                interest to you or help diagnose problems with our servers or administration of our sites. We also use
                demographic information to gather general interest in our products, to help in the analysis of product usage,
                purchasing, and target markets.
              </p>
              <p className="font-light text-[20px]">
                Please note that Tomedes does provide information to outside companies (such as a credit card processing
                company) who perform certain functions on our behalf for billing purposes. We will not permit those companies to
                use the gathered information for other than the stated purpose.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Correct/Update</h4>
              <p className="font-light text-[20px]">
                If you would like to change or modify information you have previously provided to us, please contact us
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Cookies</h4>
              <p className="font-light text-[20px]">
                Some of the websites we host place small bits of text or "cookies" onto your computer's hard drive. Cookies
                enable us to deliver content specific to your interests and keep track of your password so you do not have to
                re-enter it each time you visit our web sites. After you accept a cookie, it is passed from your browser to our
                servers each time you request a page. This cookie consists of a unique token that anonymously identifies to the
                server so that you can more easily access the features of the Tomedes Service. Please note that you may
                configure your web browser to warn you before you accept a cookie.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Customer E-mails</h4>
              <p className="font-light text-[20px]">
                Tomedes will not read or disclose e-mail content to others unless required by law or deemed necessary to operate
                our services.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Contacting the Web Site</h4>
              <p className="font-light text-[20px]">
                If you have any questions about Tomedes privacy policy or the privacy practices of our web sites, please contact
                us
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">Changes to the Privacy Policy</h4>
              <p className="font-light text-[20px]">
                In the event our Privacy Policy needs to be modified, those changes will be reflected in the next revision of
                this Privacy Policy which will be posted on the Tomedes web site. Please check back often to review any
                revisions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Privacy;
